<template>
  <div :class="`msg-user-card`" v-if="item && item.user">
    <div class="msg-user-card-content pointer" @click="chatWithUser(item)">
      <div class="msg-user-card-user">
        <UserAvatar :user="item" />
      </div>
      <div class="msg-user-card-message">
        <div class="user">
          <b class="name">@{{ item.user }}</b>
          <span class="date">{{ getTimeLapse({ date: item.lastMessageDate }) }}</span>
        </div>
        <div class="lastMessage">
          <div class="messageText" v-if="item.lastMessage" v-html="$global.getShort(decodeURIComponent(item.lastMessage), 80)"></div>
          <span class="unreadTotal" v-if="item.unreadMessages">
            <BubbleNumber class="inline" :number="item.unreadMessages" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
  methods: {
    getPicture: function(item) {
      return item && item.picture;
    },
    chatWithUser: function(item) {
      this.setQuery({ messages: item._id });
      item.unreadMessages = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.msg-user-card {
  &-content {
    @include Flex(inherit, flex-start, center);
    gap: $mpadding;
    padding: $mpadding;
    position: relative;
    &:hover {
      background-color: #f4f4f4;
    }
  }
  &-user {
    .picture {
      @include Avatar($mpadding * 3);
    }
  }
  &-message {
    flex: 1;
    word-break: break-word;
    .user {
      display: flex;
      gap: $mpadding;
      justify-content: space-between;
    }
    .lastMessage {
      display: flex;
      justify-content: space-between;
      margin: $mpadding/4 0 0 0;
    }
    .messageText {
      opacity: 0.7;
    }
    .date {
      font-size: 80%;
    }
  }
  @media (min-width: $pc_width) {
    margin: 0 $mpadding;
    &-content {
      border-radius: $mpadding/2;
    }
  }
}
</style>
